<template>
	<div class="out">
		<div class="header">
			<div class="logo Title">

				<div class="Chinese"><img src="../assets/img/return_index.png" class="return1" alt=""
						@click="$router.back()" />{{ $t('main.accauth') }} <img class="question"
						v-show="help.account_authorization" @click="showHelpContent('account_authorization')"
						src="../assets/img/question.png" alt="" srcset="" /></div>
			</div>
			<div class="right-icon">
				<img src="../assets/img/logo-icon.png" alt="">
				<div class="cc-wc">
					{{ $t('main.titleCenter') }}
				</div>
			</div>
		</div>
		<div class="content">
			<div class="zb-edit">
				<div class="zb-pic zb-edit-item jc-btn" v-for="(item, index) in list" :key="index">
					<div class="user-img">
						<img :src="item.avatar" alt="">
					</div>
					<div class="user-info">
						<div class="zb-edit-item-top">
							<div class="title-ltfe">
								{{ $t('main.acc0') }}{{ index + 1 }}
							</div>
						</div>
						<div class="zb-edit-item-bottom">
							<div class="sq-box">
								<div class="sq-info">
									{{ $t('main.aa') }}：{{ item.username }}
								</div>
								<!-- <div class="sq-btn" @click="unbind(item.id)">
									{{$t('main.unbind')}}
								</div> -->
							</div>
						</div>
						<div class="sq-tips-text">
							{{ $t('main.aoca') }}
						</div>
						<div class="sq-sub-box">
							<div class="sq-btn" @click="unbind(item.id)">
								{{ $t('main.unbind') }}
							</div>
						</div>

					</div>

				</div>
				<div class="zb-pic zb-edit-item bd-btn" v-for="item in (2 - list.length)" :key="item">
					<div class="user-img">
						<img src="../assets/img/user-defult-icon.png" alt="">
					</div>
					<div class="user-info">
						<div class="zb-edit-item-top">
							<div class="title-ltfe">
								{{ $t('main.acc0') }}{{ list.length + item }}
							</div>
						</div>
						<div class="zb-edit-item-bottom">
							<div class="sq-box">
								<div class="sq-info">
									{{ $t('main.nbu') }}
								</div>
								<!-- <div class="sq-btn" @click="goDetail()">
									{{$t('main.sa')}}
								</div> -->
							</div>
						</div>
						<div class="sq-tips-text">
							{{ $t('main.aoca') }}
						</div>
						<div class="sq-sub-box">
							<div class="sq-btn" @click="goDetail()">
								{{ $t('main.sa') }}
							</div>
						</div>
					</div>

				</div>

				<!-- <div class="submint-info">
						{{$t('main.submit')}}
					</div> -->
			</div>
		</div>
	</div>
</template>

<script>
import { Dialog } from 'vant';
export default {
	data() {
		return {
			list: [],
			help: {
				account_authorization: null
			}
		}
	},
	mounted() {
		if (!localStorage.getItem('helpinfo') || localStorage.getItem('helpinfo') == '{}') {
			this.help = {
				account_authorization: null
			}
		} else {
			if (this.$i18n.locale == 'en') {
				let data = JSON.parse(localStorage.getItem('helpinfo'))
				Object.keys(data).forEach((item) => {
					if (item.indexOf('_intl') != -1) {
						let label = item.split('_intl')[0]
						if (data[item]) {
							data[label] = data[item]
						}
					}
				})
				this.help = data
			} else {
				this.help = JSON.parse(localStorage.getItem('helpinfo'))
			}
		}
		this.axios.post('/auth/helperInfo', { type: 2, }).then((res) => {

			this.list = res.data.data.list || []
		})
	},
	methods: {
		goDetail() {
			this.$router.push('/sqDetail_col');
		},
		unbind(id) {
			this.axios.post('/auth/unBind', {
				type: 2,
				id
			}).then((res) => {
				if (res.data.code == 0) {
					weui.alert(this.$t('main.unbindSucc'), { buttons: [{ label: this.$t('main.confIrm') }] })
					this.axios.post('/auth/helperInfo', { type: 2 }).then((res) => {

						this.list = res.data.data.list || []
					})
				}
			})
		},
		showHelpContent(key) {
			Dialog.alert({ message: this.help[key], confirmButtonColor: '#2d71d7', confirmButtonText: this.$t('main.confIrm') })
		},
	}
}
</script>

<style scoped>
.out {
	padding: 0rem 0.15rem;
	min-height: 100vh;
	box-sizing: border-box;
	box-sizing: border-box;
	background: url(../assets/img/scj-in-bg.png) no-repeat center;
	background-size: cover;
}

.header {
	display: flex;
	justify-content: space-between;
	/* line-height: 0; */
	margin-bottom: 0rem;
	margin-bottom: 0rem;
	margin: 0 -0.15rem;
	padding: 0 0.2rem;
	align-items: center;
	margin-bottom: 0.38rem;
	box-shadow: 0px 5px 15px -7px rgba(0, 0, 0, 0.3);
	padding-bottom: 0.09rem;
	padding-top: 0.05rem;
	background: #fff;
}

.Title {
	font-size: 0.16rem;
	position: relative;
}

.English {
	color: #d6eedc;
	position: absolute;
	top: 0.1rem;
	left: 0.15rem;
}

.Chinese {
	color: #1b6a21;
	/* top: -0.2rem; */
	left: 0;
	position: relative;
	text-align: left;
	font-size: 0.18rem;
}

.cc-wc {
	font-size: 0.1rem;
	color: #e9630a;
}

.right-icon {
	/* width: 1rem; */
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.right-icon img {
	display: block;
	width: 100%;
	height: 100%;
	width: 0.65rem;
}

.zb-edit {
	font-size: 0.16rem;
	padding-bottom: 0.23rem;
}

.zb-edit-item {
	/* margin: 0 -0.2rem; */
	padding: 0 0.2rem;
	background: #FFFFFF;
	box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
	margin-bottom: 0.17rem;
	padding-top: 0.1rem;
	padding-bottom: 0.1rem;
	border-radius: 0.1rem;
	color: #FFFFFF;
	display: flex;
	padding: 0.15rem;
	box-sizing: border-box;
	align-items: flex-start;
}

.zb-edit-item .user-img img {
	width: 0.38rem;
	display: block;
}

.zb-edit-item .user-info {
	margin-left: 0.15rem;
	flex-grow: 1;
	line-height: normal;
}

.zb-edit-item.jc-btn {
	background: url(../assets/img/sq-item-bg1.png) no-repeat center;
	background-size: 100% 100%;
}

.zb-edit-item.bd-btn {
	background: url(../assets/img/sq-item-bg2.png) no-repeat center;
	background-size: 100% 100%;
}

.zb-edit-item-top {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 0.15rem;
	font-size: 0.18rem;
}

.title-ltfe {
	font-size: 0.18rem;
}

.title-right {
	font-size: 0.12rem;
}

.zb-edit-item-bottom input {
	height: 0.35rem;
	width: 100%;
	outline: none;
	margin-bottom: 0.15rem;
	border-radius: 6px;
	box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
	border: none;
	padding-left: 0.1rem;
	box-sizing: border-box;
}

.sq-box {
	display: flex;
	align-items: center;
	margin-bottom: 0.15rem;
	font-size: 0.15rem;
	justify-content: space-between;
}

.sq-sub-box {
	display: flex;
	justify-content: flex-end;
}

.sq-sub-box .sq-btn {
	height: 0.29rem;
	line-height: 0.29rem;
	width: 0.875rem;
	text-align: center;
	background: #FFFFFF;
	border-radius: 0.04rem;
	white-space: nowrap;
	font-size: 0.15rem;
}

.jc-btn .sq-sub-box .sq-btn {
	color: #6b7cfe;
}

.bd-btn .sq-sub-box .sq-btn {
	color: #fe7853;
}

.sq-box .sq-btn {
	width: 1.16rem;
	height: 0.4rem;
	line-height: 0.4rem;
	text-align: center;
	border-radius: 0.1rem;
	background: #3662EC;
	box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.4);
	color: #FFFFFF;
}

.sq-tips-text {
	display: flex;
	/* justify-content: flex-end; */
	align-items: center;
	font-size: 0.11rem;
	margin-bottom: 0.15rem;
}

.agree-box {
	display: flex;
	align-items: center;
}

.agree-box span {
	font-size: 0.14rem;
	font-weight: 500;
	letter-spacing: 0px;
	color: #9E9E9E;
	margin-left: 0.1rem
}

.submint-info {
	width: 100%;
	height: 0.45rem;
	line-height: 0.45rem;
	text-align: center;
	color: #FFFFFF;
	border-radius: 10px;
	background: #3662EC;
	box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.4);
	margin-top: 0.23rem;
	margin-bottom: 0.23rem;
}

.question {
	width: 0.15rem;
	height: 0.15rem;
}</style>
